




















































import { Component, Vue } from 'vue-property-decorator';
import List from '@/modules/settings/components/list/List.vue';
import ListItem from '@/modules/settings/components/list/list-item/ListItem.vue';
import AddIcon from '@/assets/images/add.svg';
import TagsModule from '@/store/modules/tags/TagsModule';
import TagsGroupsModule from '@/store/modules/tagsGroups/TagsGroupsModule';
import TagListItem from '@/modules/settings/views/directions/components/TagListItem.vue';
import TagsGroupItem from './components/TagsGroupItem.vue';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import { Tag } from '@/typings/domain';
import { Colors } from '@/modules/settings/views/directions/model';
import i18n from '@/services/i18n';

export const newRandomColor = () => {
    const usedColors = TagsModule.tags.map(el => el.color);
    const availableColors = Colors.filter(el => el !== usedColors.find(x => el === x));
    const newColor = Math.floor(Math.random() * availableColors.length);
    
    return availableColors.length ? `${availableColors[newColor]}` : '#FFFFFF';
}

export const createTag = async (newTag: { name: string; color: string }) => {
        await ApiClient.tags
            .createTag(newTag)
            .then(() => {
                Notify.success('Направление создано');
            })
            .catch(error => {     
                const data = error.response.data;

                if (data.name?.[0].includes('required')) {
                    Notify.error(i18n.t('Errors.NoEmptyFields').toString());
                } else if (data.slug?.[0].includes('unique')) {
                    Notify.error(i18n.t('Errors.NoDublicates').toString());
                }
            })
            .finally(() => TagsModule.fetchTags());
    }

@Component({
    name: 'Directions',
    components: { List, ListItem, TagListItem, TagsGroupItem },
})
export default class Directions extends Vue {
    public addIcon = AddIcon;
    public Colors = Colors;
    public newRandomColor = newRandomColor;
    public createTag = createTag;

    public get tags() {
        return TagsModule.tags;
    }

    public get tagsGroups() {
        // sort tags by name length
        TagsGroupsModule.tagsGroups.map(el => el.tags.sort((a: Tag, b: Tag) => a.name.length - b.name.length));

        return TagsGroupsModule.tagsGroups;
    }
    mounted()
    {
        TagsModule.fetchTags();
        TagsGroupsModule.fetchTagsGroups();
    }
    createTagGroup({ name, tags }: { name: string; tags: Tag[] }): void {
        const tagsArray = tags.map(el => el.id);

        ApiClient.tagsGroups
            .createTagGroup(name, tagsArray)
            .then(() => {
                Notify.success('Изменения сохранены');
            })
            .finally(() => TagsGroupsModule.fetchTagsGroups());
    }

    changeTagColor({ id, newColor }: { id: number; newColor: string }) {
        ApiClient.tags
            .setTagColor(id, newColor)
            .then(() => {
                Notify.success('Изменения сохранены');
            })
            .finally(() => {
                TagsModule.fetchTags();
                TagsGroupsModule.fetchTagsGroups();
            });
    }

    editTagName({ id, name }: { id: number; name: string }) {
        if(name) {
            ApiClient.tags
            .setTagName(id, name)
            .then(() => {
                Notify.success('Изменения сохранены');
            })
            .finally(() => {
                TagsModule.fetchTags();
                TagsGroupsModule.fetchTagsGroups();
            });
        }
    }

    editTagGroup({ id, name, tags }: { id: number; name: string; tags: number[] }): void {
        if(name) {
            ApiClient.tagsGroups
            .setTagGroup(id, name, tags)
            .then(() => {
                Notify.success('Изменения сохранены');
            })
            .finally(() => TagsGroupsModule.fetchTagsGroups());
        }
    }

    deleteTag(id: number): void {
        ApiClient.tags
            .deleteTag(id)
            .then(() => {
                Notify.success('Изменения сохранены');
            })
            .finally(() => {
                TagsModule.fetchTags();
                TagsGroupsModule.fetchTagsGroups();
            });
    }

    deleteTagGroup(id: number) {
        ApiClient.tagsGroups
            .deleteTagGroup(id)
            .then(() => {
                Notify.success('Изменения сохранены');
            })
            .finally(() => TagsGroupsModule.fetchTagsGroups());
    }
}
