
































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import EditListItem from '@/modules/settings/components/list/list-item/EditListItem.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';

import PopupDialog from '@/components/ui/popup-dialog/PopupDialog.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import { Tag } from '@/typings/domain';
import Editor from '@/modules/settings/views/directions/components/Editor.vue';
import { Colors } from '@/modules/settings/views/directions/model';

@Component({
    name: 'TagList',
    components: {
        Dropdown,
        Icon,
        ContextMenuSpawner,
        EditListItem,
        PopupDialog,
        ContextMenuLink,
        ContextMenuHeader,
        DeleteContext,
        Editor,
    },
})
export default class TagList extends Vue {
    $isMobile: boolean;

    @Prop() tag: Tag;
    @Prop() editorHeader: string;
    
    // Base values
    public hasInteraction = false;
    public isHovered = false;
    public isEdit = false;
    public isDeleteConfirmOpened = false;
    public isOptionsOpened = false;
    public isColorPickerOpened = false;
    public Colors = Colors;

    // Methods

    openDeleteConfirm() {
        this.isDeleteConfirmOpened = true;
        this.isHovered = false;
        this.isOptionsOpened = false;
    }

    openTagEdit() {
        this.isEdit = true;
        this.isOptionsOpened = false;
    }

    closeOptions() {
        this.isOptionsOpened = false;
        this.isHovered = false;
    }

    closeDeleteConfirm() {
        this.isDeleteConfirmOpened = false;
    }

    closeColorPicker() {
        this.isColorPickerOpened = false;
    }

    @Emit('changeName')
    public editAction(newName: Tag) {
        this.isEdit = false;
        this.isOptionsOpened = false;
        if(newName.name) {
            return {id: this.tag.id, name: newName.name};
        } else {            
            return 
        }
    }

    @Emit('changeTagColor')
    public changeTagColor(newColor: string) {
        this.isColorPickerOpened = false;
        this.tag.color = newColor;

        return {id: this.tag.id, newColor};
    }

    @Emit('delete')
    public deleteAction() {
        this.isDeleteConfirmOpened = false;

        return this.tag.id;
    }

    // Event driven logic

    @Watch('isDeleteConfirmOpened')
    private fixHoverState() {
        if (!this.isDeleteConfirmOpened) this.isHovered = false;
    }

    @Watch('isEdit')
    @Watch('isHovered')
    @Watch('isDeleteConfirmOpened')
    @Watch('isOptionsOpened')
    private setHasInteraction() {
        this.hasInteraction = this.isEdit || this.isDeleteConfirmOpened || this.isOptionsOpened;
    }
}
