import { render, staticRenderFns } from "./TagListItem.vue?vue&type=template&id=711c56db&scoped=true&"
import script from "./TagListItem.vue?vue&type=script&lang=ts&"
export * from "./TagListItem.vue?vue&type=script&lang=ts&"
import style0 from "./TagListItem.vue?vue&type=style&index=0&id=711c56db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711c56db",
  null
  
)

export default component.exports