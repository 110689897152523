export interface Leader {
  value: string;
}

export interface ListItem {
  value: string;
  color?: string;
  leaders?: Array<Leader>;
}

export const Colors = [
  '#DF911B', '#BC5B72', '#573482', '#54A7C1', 
  '#E4A94F', '#9A214C', '#784FBA', '#238AAB',
  '#DA7D11', '#6A0979', '#490AB0', '#2F997F',
  '#C85617', '#A034A2', '#114798', '#10756F',  
  '#B4410F', '#872A7D', '#30417C', '#32874F',
  '#B85151', '#6D2565', '#312A84', '#7AAD5B',
  '#9E2D09', '#9C3F6C', '#4871DC', '#669B22',
  '#B02155', '#900A5B', '#5448DC', '#0E6641',  
]